import { initSketch, initSketch as starFieldSketch } from "./modules/visual/star-field";
import { initSketch as marchingSquaresSketch } from "./modules/visual/marching-squares";
import { initSketch as perlinNoiseSketch } from "./modules/visual/perlin-noise";

export const VOLUME_LEVEL = 0.6;

export type MenuItem = {
  name: string,
  sketch?: typeof initSketch
};

// noises work kinda diff as they load from public
export const noises: MenuItem[] = [
  { name: 'pink'},
  { name: 'brown'},
  { name: 'white'}
];
export const initialNoise = noises[0];

// where as these are imported. can import noises? todo?
export const visuals: MenuItem[] = [
  {name: 'star-field', sketch: starFieldSketch },
  {name: 'marching-squares', sketch: marchingSquaresSketch },
  {name: 'perlin-noise', sketch: perlinNoiseSketch }
];
export const initalVisual = visuals[0];