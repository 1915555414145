import Audio from "./Audio";
import Visualizer from "./Visualizer";
import { useState } from "react";
import { visuals, noises, initalVisual, initialNoise } from "../constants";
import { _generateMenuOptions } from "./MenuItem";

export default function NoiseAudio() {
    let [selectedVisual, setSelectedVisual] = useState(initalVisual);
    let [selectedNoise, setSelectedNoise] = useState(initialNoise);

    const noiseOptions = _generateMenuOptions(noises, setSelectedNoise, selectedNoise);
    const visualOptions = _generateMenuOptions(visuals, setSelectedVisual, selectedVisual);

    return <>
        <div className="menu">
            <ul className="noise">{noiseOptions}</ul>
            <ul className="visual">{visualOptions}</ul>
        </div>
        <Audio selectedNoise={selectedNoise.name} />
        {!!selectedVisual.sketch && <Visualizer sketch={selectedVisual.sketch} />}        
    </>
}
