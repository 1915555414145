import { initialNoise, noises } from "../constants";

export default class AudioControls {
    public isLoaded: boolean = false;
    private ctx: AudioContext;
    private gainNode: GainNode;
    private noiseNodes: Record<string, AudioWorkletNode> = {};
    private activeNode: AudioWorkletNode | undefined;

    constructor() {
        this.ctx = new AudioContext();
        this.gainNode = this.ctx.createGain();
        this.gainNode.gain.setValueAtTime(0, 0);
        this.gainNode.connect(this.ctx.destination);
        this.loadModules();
    }

    private async loadModules() {
        await Promise.all(noises.map(async ({name}) => {
            await this.ctx.audioWorklet.addModule(`modules/audio/${name}.js`);
            const noiseNode = new AudioWorkletNode(this.ctx, `${name}-noise-processor`);
            this.noiseNodes[name] = noiseNode;
        }));
        this.isLoaded = true;
        this.selectNoise(initialNoise.name);
        console.log(' - - modulesloaded: selecting', initialNoise.name);
    }

    public selectNoise(selectedNoise: string) {
        if (!this.isLoaded) { return; }
        this.activeNode?.disconnect();
        this.activeNode = this.noiseNodes[selectedNoise];
        this.activeNode.connect(this.gainNode);
        console.log(' - - selectnoise: ', selectedNoise)
    }

    public mute(shouldMute: boolean) {
        if (!this.isLoaded) { return; }
        const newVolume = shouldMute ? 0 : 0.8;
        this.gainNode.gain.setValueAtTime(newVolume, 0);
        console.log(' - - newvolume: ', newVolume);
    }
}